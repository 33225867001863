<template>
  <div>
    <Header />
    <div class="bg-mid-grey mt-5">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pt-5 pb-5">
            <h1 class="page-header">SHARE OF RANIBIZUMAB - <i>NEW PATIENTS</i></h1>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-light-grey">
      <div class="grid-container">
        <div class="grid-x grid-padding-x">
          <div v-if="timeframe === 3" class="cell small-12 medium-3 medium-offset-3 pt-5">
            <h5 class="text-center">Current situation</h5>
          </div>
          <div v-else class="cell small-12 medium-3 medium-offset-2 pt-5">
            <h5 class="text-center">Current situation</h5>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-3 medium-offset-2 pt-5">
            <h5 class="text-center">Future situation</h5>
          </div>
          <div v-else class="cell small-12 medium-3 pt-5">
            <h5 class="text-center">Future situation</h5>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-1 medium-offset-3">
            <p class="mb-0 text-center">Year 1</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 2</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 3</p>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2">
            <p class="mb-0 text-center">Year 1</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 2</p>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1">
            <p class="mb-0 text-center">Year 3</p>
          </div>
        </div>
        <div v-for="(drug, index) in drugs" :key="index" class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-5">
            <button class="button expanded blue-active">
              <i v-if="drug === 'byoviz'" class="plain">Byooviz</i>
              <i v-else class="plain">{{ drug }}</i>
            </button>
          </div>
          <div class="cell small-12 medium-1 medium-offset-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearOneShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearOneShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearOneShare"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearTwoShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearTwoShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearTwoShare"></number>
          </div>
          <div  v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearThreeShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearThreeShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearThreeShare"></number>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearOneFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearOneFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearOneFutureShare"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearTwoFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearTwoFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearTwoFutureShare"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" v-if="drug === 'byoviz'" type="text" class="empty text-center" v-model="byovizYearThreeFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'lucentis'" type="text" class="empty text-center" v-model="lucentisYearThreeFutureShare"></number>
            <number v-number="percentage" v-if="drug === 'biosimilars 2'" type="text" class="empty text-center" v-model="biosimilarYearThreeFutureShare"></number>
          </div>
          <div class="cell small-12">
            <hr class="wide orange-line mt mb" />
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 medium-2 pt-5">
            <button class="button expanded blue-active">Total (100%)</button>
          </div>
          <div class="cell small-12 medium-1 medium-offset-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearOneTotalShare"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearTwoTotalShare"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearThreeTotalShare"></number>
          </div>
          <div class="cell small-12 medium-1 medium-offset-2 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearOneTotalShareFuture"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearTwoTotalShareFuture"></number>
          </div>
          <div v-if="timeframe === 3" class="cell small-12 medium-1 pt-5">
            <number v-number="percentage" disabled type="text" class="fixed text-center" v-model="yearThreeTotalShareFuture"></number>
          </div>
        </div>
        <div v-if="timeframe === 3" class="grid-x grid-padding-x pt-20 pb-5">
          <div
            v-if="yearOneTotalShare === 100 && yearOneTotalShareFuture === 100 && yearTwoTotalShare === 100 && yearTwoTotalShareFuture === 100 && yearThreeTotalShare === 100 && yearThreeTotalShareFuture === 100"
            class="cell small-12 medium-2 medium-offset-10 text-right">
            <router-link v-if="patientTypes.length === 2" to="/share-switch">
              <button class="button">Next</button>
            </router-link>
            <router-link v-else to="/summary">
              <button class="button">Next</button>
            </router-link>
          </div>
          <div v-else class="cell small-12 medium-2 medium-offset-10 text-right">
            <button disabled class="button disabled">Next</button>
          </div>
        </div>
        <div v-else class="grid-x grid-padding-x pt-20 pb-5">
          <div v-if="yearOneTotalShare === 100 && yearOneTotalShareFuture === 100" class="cell small-12 medium-2 medium-offset-10 text-right">
            <router-link v-if="patientTypes.length === 2" to="/share-switch">
              <button class="button">Next</button>
            </router-link>
            <router-link v-else to="/summary">
              <button class="button">Next</button>
            </router-link>
          </div>
          <div v-else class="cell small-12 medium-2 medium-offset-10 text-right">
            <button disabled class="button disabled">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';

export default {
  name: 'Share',
  components: {
    Header,
  },
  data() {
    return {
      percentage: {
        masked: true,
        suffix: '%',
      },
    };
  },
  computed: {
    hash() { return this.$store.state.hash; },
    drugs() { return this.$store.state.drugs; },
    timeframe() { return this.$store.state.timeframe; },
    patientTypes() { return this.$store.state.patientTypes; },
    byovizYearOneShare: {
      get() { return this.$store.state.byovizYearOneShare; },
      set(value) { this.$store.commit('updatebyovizYearOneShare', value); },
    },
    byovizYearTwoShare: {
      get() { return this.$store.state.byovizYearTwoShare; },
      set(value) { this.$store.commit('updatebyovizYearTwoShare', value); },
    },
    byovizYearThreeShare: {
      get() { return this.$store.state.byovizYearThreeShare; },
      set(value) { this.$store.commit('updatebyovizYearThreeShare', value); },
    },
    lucentisYearOneShare: {
      get() { return this.$store.state.lucentisYearOneShare; },
      set(value) { this.$store.commit('updatelucentisYearOneShare', value); },
    },
    lucentisYearTwoShare: {
      get() { return this.$store.state.lucentisYearTwoShare; },
      set(value) { this.$store.commit('updatelucentisYearTwoShare', value); },
    },
    lucentisYearThreeShare: {
      get() { return this.$store.state.lucentisYearThreeShare; },
      set(value) { this.$store.commit('updatelucentisYearThreeShare', value); },
    },
    biosimilarYearOneShare: {
      get() { return this.$store.state.biosimilarYearOneShare; },
      set(value) { this.$store.commit('updatebiosimilarYearOneShare', value); },
    },
    biosimilarYearTwoShare: {
      get() { return this.$store.state.biosimilarYearTwoShare; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoShare', value); },
    },
    biosimilarYearThreeShare: {
      get() { return this.$store.state.biosimilarYearThreeShare; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeShare', value); },
    },
    byovizYearOneFutureShare: {
      get() { return this.$store.state.byovizYearOneFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearOneFutureShare', value); },
    },
    byovizYearTwoFutureShare: {
      get() { return this.$store.state.byovizYearTwoFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearTwoFutureShare', value); },
    },
    byovizYearThreeFutureShare: {
      get() { return this.$store.state.byovizYearThreeFutureShare; },
      set(value) { this.$store.commit('updatebyovizYearThreeFutureShare', value); },
    },
    lucentisYearOneFutureShare: {
      get() { return this.$store.state.lucentisYearOneFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearOneFutureShare', value); },
    },
    lucentisYearTwoFutureShare: {
      get() { return this.$store.state.lucentisYearTwoFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearTwoFutureShare', value); },
    },
    lucentisYearThreeFutureShare: {
      get() { return this.$store.state.lucentisYearThreeFutureShare; },
      set(value) { this.$store.commit('updatelucentisYearThreeFutureShare', value); },
    },
    biosimilarYearOneFutureShare: {
      get() { return this.$store.state.biosimilarYearOneFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearOneFutureShare', value); },
    },
    biosimilarYearTwoFutureShare: {
      get() { return this.$store.state.biosimilarYearTwoFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearTwoFutureShare', value); },
    },
    biosimilarYearThreeFutureShare: {
      get() { return this.$store.state.biosimilarYearThreeFutureShare; },
      set(value) { this.$store.commit('updatebiosimilarYearThreeFutureShare', value); },
    },
    yearOneTotalShare() { return (parseInt(this.byovizYearOneShare, 10) + parseInt(this.lucentisYearOneShare, 10) + parseInt(this.biosimilarYearOneShare, 10)); },
    yearTwoTotalShare() { return (parseInt(this.byovizYearTwoShare, 10) + parseInt(this.lucentisYearTwoShare, 10) + parseInt(this.biosimilarYearTwoShare, 10)); },
    yearThreeTotalShare() { return (parseInt(this.byovizYearThreeShare, 10) + parseInt(this.lucentisYearThreeShare, 10) + parseInt(this.biosimilarYearThreeShare, 10)); },
    yearOneTotalShareFuture() { return (parseInt(this.byovizYearOneFutureShare, 10) + parseInt(this.lucentisYearOneFutureShare, 10) + parseInt(this.biosimilarYearOneFutureShare, 10)); },
    yearTwoTotalShareFuture() { return (parseInt(this.byovizYearTwoFutureShare, 10) + parseInt(this.lucentisYearTwoFutureShare, 10) + parseInt(this.biosimilarYearTwoFutureShare, 10)); },
    yearThreeTotalShareFuture() { return (parseInt(this.byovizYearThreeFutureShare, 10) + parseInt(this.lucentisYearThreeFutureShare, 10) + parseInt(this.biosimilarYearThreeFutureShare, 10)); },
  },
  methods: {
    sendPage() {
      this.$store.commit('setPage', 'share');
    },
    checkHashExists() {
      if (this.hash.length === 0) {
        this.$router.push('/inactive-link');
      }
    },
    checkShareType() {
      // eslint-disable-next-line
      const patientTypes = this.$store.state.patientTypes;
      let newPatients = false;
      for (let i = 0; patientTypes.length > i; i += 1) {
        if (patientTypes[i] === 'new') newPatients = true;
      }
      if (newPatients === false) this.$router.push('/share-switch');
    },
  },
  mounted() {
    this.checkHashExists();
    this.sendPage();
    this.checkShareType();
  },
};
</script>
